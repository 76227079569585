<template>
<div class="page-box">
  <el-button @click="addContent">新增</el-button>
  <div class="table-box p-t-15">
    <el-table
        :data="list"
        :row-style="{height:'40px'}" :header-row-style="{height:'40px'}" border
        height="100%"
        style="width: 100%"
    >
      <el-table-column
          align="center"
          prop="id"
          label="ID"></el-table-column>
      <el-table-column
          align="center"
          prop="title"
          label="标题"></el-table-column>
      <el-table-column
          align="center"
          prop="title"
          label="类型">
        <template slot-scope="scope">
          <span>{{ typeMap[scope.row.type]}}</span>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="jump_url"
          label="链接">
        <template slot-scope="scope">
          <router-link :to="scope.row.jump_url">{{ scope.row.jump_url || '--'}}</router-link>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="create_time"
          label="添加时间"></el-table-column>
      <el-table-column
          align="center"
          prop="update_time"
          label="最后编辑时间"></el-table-column>
      <el-table-column
          align="center"
          prop="update_by"
          label="最后编辑人"></el-table-column>
      <el-table-column
          align="center"
          prop="title"
          label="操作">
        <template slot-scope="scope">
          <el-button type="primary" @click="editContent(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</div>
</template>

<script>
import { getContentList } from "@/api/content";
import {statusCode} from "@/util/statusCode";

export default {
name: "index",
  data() {
    return {
      list: [],
      typeMap: {
        "1":'关于我们',
        '2':'用户协议',
        '3':'隐私政策',
        '4':'联系我们',
        '5':'开播教程',
        '6':'开播工具',
        '7':'直播规范',
        '8':'直播协议',
        '9':'注销协议',
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      try {
        const { data } = await getContentList()
        if (data.code === statusCode.success) {
          this.list = data.data.data || []
        }
      } catch (e) {
        console.log('出错了', e)
      }
    },
    addContent() {
      this.$router.push({
        path: '/contentEdit/edit',
      })
    },
    editContent(row) {
      this.$router.push({
        path: '/contentEdit/edit',
        query: {
          id: row.id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.page-box {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  .table-box {
    height: calc(100% - 50px);
  }
}
</style>
